import { faFileAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Accept } from 'react-dropzone';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { cleanFileName } from '../../../util/file';
import FileUpload from '../FileUpload';
import RadioButtonsGroup from '../RadioButtonsGroup';
import { FormContext } from './FormWrapper';
import { SelectComponents } from 'react-select/src/components';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const dateOfBirthMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const last4SSNMask = [/\d/, /\d/, /\d/, /\d/];

const capitalize = (text: string): string => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const camelCaseToSeparateWords = (text: string): string => {
  if (!text) return '';
  return text.replace(/[A-Z]/g, letter => ` ${letter}`);
};

type FormFieldProps<T> = {
  name: keyof T;
  withoutLabel?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  value?: any;
} & (
  | { type: 'checkbox' }
  | { type: 'text'; onChange?: (value: any) => void }
  | { type: 'email'; onChange?: (value: any) => void }
  | { type: 'textarea'; rows?: number; onChange?: (value: any) => void }
  | {
      type: 'radio';
      onChange?: (value: any) => void;
      value: string;
      options: { label: string; value: string | number | boolean }[];
    }
  | {
      type: 'select';
      options: { label: string | number; value: string | number }[];
      isLoading?: boolean;
      isMulti?: boolean;
      onChange?: (value: any) => void;
      components?:
        | Partial<
            SelectComponents<
              {
                label: string | number;
                value: string | number;
              },
              boolean
            >
          >
        | undefined;
    }
  | {
      type: 'async-select';
      loadOptions: (
        inputValue: string,
        callback: (options: Array<{ value: string; label: string }>) => void
      ) => any;
      options?: { label: string; value: string | number }[];
      isLoading?: boolean;
      isMulti?: boolean;
      onChange?: (value: any) => void;
      components?:
        | Partial<
            SelectComponents<
              {
                label: string;
                value: string | number;
              },
              boolean
            >
          >
        | undefined;
    }
  | {
      type: 'number';
      allowDecimal?: boolean;
      allowZero?: boolean;
      onChange?: (value: any) => void;
    }
  | {
      type: 'mask';
      mask: 'phoneNumber' | 'dateOfBirth' | 'last4SSN';
      onChange?: (value: any) => void;
    }
  | {
      type: 'file';
      filenamePath?: keyof T;
      onDropSuccess?: (value: { file: string; name: string }) => void;
      onDropFailure?: (errors: any) => void;
      acceptedFileTypes?: Accept;
      heading?: string;
      subheading?: string;
    }
);

const FormField = <T,>(props: FormFieldProps<T>) => {
  const { formik } = useContext(FormContext);

  const labelClasses = classNames({
    'sans-serif text-gray-500 ': true,
    'uppercase mb-1 text-sm': props.type !== 'checkbox',
    'text-red-500': formik!.touched[props.name] && formik!.errors[props.name]
  });

  const inputClasses = classNames({
    'appearance-none block w-full rounded-md shadow-sm':
      props.type !== 'checkbox',
    'placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm':
      props.type !== 'checkbox',
    'border border-gray-300  px-3 py-2':
      props.type !== 'select' &&
      props.type !== 'async-select' &&
      props.type !== 'checkbox'
  });

  const fieldProps = { ...formik!?.getFieldProps(props.name) };

  const getValue = () => {
    if (props.type !== 'select') {
      return (
        fieldProps.value ||
        (props.type === 'number' && props.allowZero ? 0 : '')
      );
    }

    if (props.isMulti) {
      return props.options.filter(opt => fieldProps.value?.includes(opt.value));
    }

    return props.options.find(opt => opt.value === fieldProps.value);
  };

  const inputProps = {
    ...fieldProps,
    id: props.name as string,
    className: inputClasses,
    'aria-label': props.label,
    disabled: props.disabled,
    placeholder:
      props.placeholder ||
      camelCaseToSeparateWords(capitalize(props.name as string)),
    autoFocus: props.autoFocus,
    onChange: (e: any) => {
      if (props.type === 'file') {
        return;
      }
      if (props.type === 'checkbox') {
        fieldProps.onChange(e);
      } else if (props.type !== 'select') {
        fieldProps.onChange(e);
        props.onChange?.(e);
      } else {
        if (props.isMulti) {
          formik?.setFieldValue(
            props.name as string,
            e?.map((el: any) => el?.value)
          );
        } else {
          formik?.setFieldValue(props.name as string, e?.value);
        }
        props.onChange?.(e);
      }
    },
    value: props.value ?? getValue(),
    checked:
      props.type === 'checkbox'
        ? fieldProps.checked || !!fieldProps.value
        : undefined
  };

  const onFileLoadSuccess = async (
    files: any[],
    fileCallback: Function,
    filenameCallback: Function
  ) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () =>
        resolve({
          file: reader.result,
          name: cleanFileName(files[0].name)
        });
      reader.onerror = error => reject(error);
    }).then(({ file, name }: any) => {
      fileCallback(file);
      filenameCallback(name);

      return { file, name };
    });
  };

  const input = () => {
    switch (props.type) {
      case 'checkbox':
        return <input type="checkbox" {...inputProps} />;
      case 'text':
        return <input type="input" {...inputProps} />;
      case 'email':
        return <input type="email" {...inputProps} />;
      case 'textarea':
        return <textarea {...inputProps} />;
      case 'radio':
        return (
          <>
            <div className="mt-4 min-h-[2.5rem]">
              <RadioButtonsGroup
                {...inputProps}
                value={props.value}
                options={props.options}
                labelClassName={'text-sm font-light'}
              />
            </div>
          </>
        );
      case 'number':
        return (
          <input
            type="number"
            {...inputProps}
            onKeyPress={event => {
              if (/[,.eE]/.test(event.key) && !props.allowDecimal) {
                event.preventDefault();
              }
            }}
          />
        );
      case 'mask':
        return (
          <MaskedInput
            mask={
              props.mask === 'phoneNumber'
                ? phoneNumberMask
                : props.mask === 'dateOfBirth'
                ? dateOfBirthMask
                : props.mask === 'last4SSN'
                ? last4SSNMask
                : (null as any)
            }
            {...inputProps}
          />
        );
      case 'select':
        return (
          <Select
            {...inputProps}
            options={props.options}
            isMulti={props.isMulti}
            isLoading={props.isLoading || false}
            components={props.components}
          />
        );
      case 'async-select':
        return (
          <AsyncSelect
            {...inputProps}
            loadOptions={props.loadOptions}
            isMulti={props.isMulti}
            defaultOptions
            cacheOptions
            isLoading={props.isLoading || false}
            options={props.options}
            onChange={props.onChange}
            components={props.components}
          />
        );
      case 'file':
        return (
          <>
            {formik!.values[props.name] ? (
              <div className="relative flex w-max items-center rounded-md border py-2 px-4 border-gray-300">
                <FontAwesomeIcon icon={faFileAlt} className="mr-2 text-sm" />
                <span className="block">
                  {formik!.values[props.filenamePath]}
                </span>
                <span
                  className="absolute -right-2 -top-2 h-4 w-4  cursor-pointer rounded-full bg-red-500 text-center text-white flex items-center justify-center"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    formik!.setFieldValue(props.name as string, undefined);
                    formik!.setFieldValue(
                      props.filenamePath as string,
                      undefined
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} className="fa-sm" />
                </span>
              </div>
            ) : (
              <FileUpload
                heading={props.heading ?? 'Upload your file'}
                subheading={
                  props.subheading ?? 'Add a .doc, .docx or .pdf file'
                }
                isLoading={false}
                onDropSuccess={async (files: any) => {
                  const res = await onFileLoadSuccess(
                    files,
                    formik!.setFieldValue.bind(null, props.name as string),
                    formik!.setFieldValue.bind(
                      null,
                      props.filenamePath as string
                    )
                  );
                  props.onDropSuccess?.(res);
                }}
                onDropFailure={(errors: any) => {
                  toast.error(
                    `${errors?.[0]?.errors?.[0]?.message ||
                      'Something went wrong'}`,
                    {}
                  );
                  props.onDropFailure?.(errors);
                }}
                multiple={false}
                acceptedFileTypes={
                  props.acceptedFileTypes || {
                    'application/*': ['.doc', '.docx', '.pdf']
                  }
                }
              />
            )}
          </>
        );
    }
  };

  return (
    <div className={`${props.className} flex flex-col`}>
      <div
        className={`${props.type === 'checkbox' &&
          'flex flex-row-reverse gap-x-2 items-center justify-end'}`}
      >
        {!props.withoutLabel && (
          <label htmlFor={props.name as string} className={labelClasses}>
            {props.label ||
              camelCaseToSeparateWords(capitalize(props.name as string))}
            {props.required && <span className="ml-1 text-red-500">*</span>}
          </label>
        )}
        {input()}
      </div>
      {formik!.touched[props.name] && formik!.errors[props.name] && (
        <span className="text-red-500 text-sm sans-serif mt-1">
          {formik!.errors[props.name]}
        </span>
      )}
    </div>
  );
};

export default FormField;
