import React, { useRef } from 'react';
import Button from './common/Button';
import ModalWrapper from './common/ModalWrapper';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  navigateToSignin: () => void;
  content?: string;
}

const SignInModal = ({ isOpen, onClose, navigateToSignin, content }: Props) => {
  const cancelButtonRef = useRef(null);

  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      <div className="flex items-center flex-col py-4">
        <div className="mt-4">
          {content || 'Please log into your account to proceed'}
        </div>
      </div>
      <div className="mt-5 flex justify-center">
        <div>
          <Button type="button" onClick={navigateToSignin} text="Log in" />
        </div>
        <div className="ml-4 my-auto">
          <button
            type="button"
            className="sans-serif text-red-500 px-2 py-1 focus:outline-none"
            onClick={onClose}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SignInModal;
