import React, { useContext, useState } from 'react';
import Card from '../common/Card';
import { eventSectionsPurchase, applyCouponToUserCart } from '../members/api';
import { toast } from 'react-toastify';
import MembersPageTitle from '../members/MembersPageTitle';
import Button from '../common/Button';
import EventSectionsForm, {
  calculateCartTotal,
  EventSectionsCartCouponUseModel,
  EventSectionsCartModel
} from './EventSectionsForm';
import { AuthContext } from '../../context/AuthContext';
import { FormField } from '../common/Form';
import { CartCouponInfo } from './CartCouponInfo';

interface EventSectionPurchaseModel {
  id: string;
  ticketTypeId: string;
  numberOfTickets: number;
  name: string;
}

export const calculateCartTotalWithCoupon = (
  cart: EventSectionsCartModel,
  couponUse: EventSectionsCartCouponUseModel
) => {
  const cartTotal = calculateCartTotal(cart);
  if (!couponUse?.coupon) {
    return cartTotal;
  }
  if (couponUse.coupon.type === 'FIXED') {
    const newTotal = cartTotal - (couponUse.coupon.discountAmount || 0);
    if (newTotal < 0) {
      return 0;
    }
    return newTotal;
  }
  if (couponUse.coupon.type === 'PERCENTAGE') {
    const newTotal =
      cartTotal -
      (cartTotal * (couponUse.coupon.discountPercentage || 0)) / 100;
    if (newTotal < 0) {
      return 0;
    }
    return newTotal;
  }
  return cartTotal;
};

const EventSectionsCheckout = ({ path }: { path: string }) => {
  const auth = useContext(AuthContext);
  const cart = auth?.authState?.cart?.payload as EventSectionsCartModel;
  const couponUse = (auth?.authState?.cart as any)
    ?.couponUse as EventSectionsCartCouponUseModel;
  const [loading, setLoading] = useState(false);
  const [loadingPO, setLoadingPO] = useState(false);
  const [loadingApplyCoupon, setLoadingApplyCoupon] = useState(false);
  const [coupon, setCoupon] = useState('');

  const isFree = cart?.items?.every(item => item.isFree);

  const proceedWithPayment = async () => {
    setLoading(true);
    try {
      const data: EventSectionPurchaseModel[] = cart?.items?.map(item => ({
        id: item.id,
        ticketTypeId: item?.ticketType?.id,
        numberOfTickets: item.numberOfTickets,
        name: item.title,
        type: item.type
      }));

      const couponCode = couponUse?.coupon?.code || '';

      const result = await eventSectionsPurchase({
        type: 'CREDIT_CARD',
        items: data,
        couponCode
      });
      setLoading(false);
      window.location.href = result.url;
    } catch (err) {
      toast.error('Something went wrong');
      setLoading(false);
    }
  };

  const proccedWithPO = async () => {
    setLoadingPO(true);
    try {
      const data: EventSectionPurchaseModel[] = cart?.items?.map(item => ({
        id: item.id,
        ticketTypeId: item.ticketType.id,
        numberOfTickets: item.numberOfTickets,
        name: item.title,
        type: item.type
      }));

      const couponCode = couponUse?.coupon?.code || '';

      const result = await eventSectionsPurchase({
        type: 'PO',
        items: data,
        couponCode
      });
      setLoadingPO(false);
      window.location.href = result.url;
    } catch (err) {
      toast.error('Something went wrong');
      setLoadingPO(false);
    }
  };

  const applyCoupon = async () => {
    if (!coupon) {
      toast.error('Coupon code is required');
      return;
    }
    setLoadingApplyCoupon(true);
    try {
      const result = await applyCouponToUserCart({ code: coupon });
      if (result?.state !== 'SUCCESS') {
        throw new Error(result?.message || 'Something went wrong');
      }
      await auth?.reloadUser();
    } catch (err) {
      toast.error((err as any)?.message || 'Something went wrong');
    } finally {
      setLoadingApplyCoupon(false);
    }
  };

  return (
    <>
      <MembersPageTitle title="Event Cart" />
      <Card className="mt-4">
        {cart?.items?.length ? (
          <div className="max-w-lg bg-white">
            <EventSectionsForm
              cart={cart}
              onSubmit={proceedWithPayment}
              submitText={isFree ? 'Continue' : 'Pay by Credit Card'}
              loading={loading}
              disabled={loading || loadingPO}
              additionalContent={
                !isFree ? (
                  !couponUse?.coupon ? (
                    <div className="flex gap-2 items-end mb-4">
                      <FormField
                        type="text"
                        name={'coupon'}
                        onChange={e => setCoupon(e.target.value || '')}
                      />
                      <div>
                        <Button
                          type="button"
                          text="Apply"
                          isLoading={loadingApplyCoupon}
                          disabled={loading || loadingPO || loadingApplyCoupon}
                          onClick={applyCoupon}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <CartCouponInfo
                        cart={cart}
                        couponUse={couponUse}
                        removeBtn={
                          <Button
                            className="flex gap-2"
                            type="button"
                            isLoading={loadingApplyCoupon}
                            onClick={async () => {
                              setLoadingApplyCoupon(true);
                              try {
                                await applyCouponToUserCart({ code: '' });
                                await auth?.reloadUser();
                              } catch (err) {
                                toast.error(
                                  (err as any)?.message ||
                                    'Something went wrong'
                                );
                              } finally {
                                setLoadingApplyCoupon(false);
                              }
                            }}
                            text="Remove"
                            color="error"
                            size="sm"
                          />
                        }
                      />
                    </>
                  )
                ) : null
              }
              additionalButtons={
                !isFree && (
                  <Button
                    isLoading={loadingPO}
                    type="button"
                    disabled={loading || loadingPO}
                    text={'Pay with Purchase Order'}
                    onClick={proccedWithPO}
                  />
                )
              }
            />
          </div>
        ) : (
          <div className="p-4 text-primary flex justify-center italic">
            Your cart is empty
          </div>
        )}
      </Card>
    </>
  );
};

export default EventSectionsCheckout;
