import { RadioGroup } from '@headlessui/react';
import { Field } from 'formik';
import React from 'react';

const CheckIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="9.5" fill="#E9E9E9" />
      {props.checked ? (
        <circle
          cx="9.5"
          cy="9.5"
          r="7.5"
          fill={props.disabled ? '#64748b' : '#2C67AE'}
        />
      ) : null}
    </svg>
  );
};

const RadioButtonsGroup = React.forwardRef(
  (
    {
      value,
      name,
      onChange,
      options,
      labelClassName = '',
      disabled = false
    }: {
      value: any;
      name: any;
      onChange: (s: any) => void;
      options: { value: any; label: string | number }[];
      labelClassName?: string;
      disabled?: boolean;
    },
    ref
  ) => {
    return (
      <RadioGroup
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        ref={ref as any}
      >
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="flex">
          {options.map(item => (
            <RadioGroup.Option key={item.value} value={item.value}>
              {({ checked }) => (
                <>
                  <div className="mr-4 flex w-full items-center justify-between">
                    <div
                      className={` text-sm ${!disabled && 'cursor-pointer'} `}
                    >
                      <RadioGroup.Label
                        as="div"
                        className={`font-medium text-gray-900`}
                      >
                        <div className="flex">
                          <CheckIcon
                            checked={checked}
                            className="h-6 w-6"
                            disabled={disabled}
                          />
                          <span className={`ml-2 ${labelClassName} `}>
                            {item.label}
                          </span>
                        </div>
                      </RadioGroup.Label>
                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  }
);

export default RadioButtonsGroup;
