import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useStaticQuery, graphql } from 'gatsby';
import './layout.css';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <Navbar siteTitle={data.site.siteMetadata.title} />
      <div className="bg-gray-50">
        <main>{children}</main>
      </div>
      <div className="my-10">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
