import classnames from 'classnames';
import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import Button from './common/Button';
import NavItem from './common/NavItem';
import SubNavItem from './common/SubNavItem';
import './Navbar.css';
import { ShoppingCartIcon } from '@heroicons/react/solid';
import SignInModal from './SignInModal';
import EventSectionsCart from './event-sections/EventSectionsCart';
import { updateCartAndReloadUser } from './members/api';
import Img from 'gatsby-image';
import { getUrl } from '../util';
import { EventSectionsCartCouponUseModel } from './event-sections/EventSectionsForm';

interface MobileMenuSubNavLinkProps {
  label: string;
  link: string;
  children?: React.ReactNode;
}
const MobileMenuSubNavLink: React.FC<MobileMenuSubNavLinkProps> = props => {
  return (
    <Link
      to={props.link}
      className="block px-3 py-2 ml-4 text-base font-medium rounded-md text-light_green hover:bg-primary-dark hover:text-blue-100 sans-serif"
    >
      {props.label}
    </Link>
  );
};

interface MobileMenuNavLinkProps {
  label: string;
  link: string;
  externalLink?: string;
  children?: React.ReactNode;
}
const MobileMenuNavLink: React.FC<MobileMenuNavLinkProps> = props => {
  return (
    <>
      {props.externalLink ? (
        <a
          href={props.externalLink ? props.externalLink : '#'}
          className="block px-3 py-2 text-base font-medium rounded-md text-light_green hover:bg-primary-dark hover:text-blue-100 sans-serif"
        >
          {props.label}
        </a>
      ) : (
        <Link
          to={props.link}
          className="block px-3 py-2 text-base font-medium rounded-md text-light_green hover:bg-primary-dark hover:text-blue-100 sans-serif"
        >
          {props.label}
        </Link>
      )}
      {props.children}
    </>
  );
};

interface MobileMenuProps {
  showMobileMenu: boolean;
  authState: any;
}
const MobileMenu: React.FC<MobileMenuProps> = props => {
  const mobileMenuClassnames = classnames({
    'bg-primary lg:hidden': true,
    hidden: !props.showMobileMenu,
    block: !!props.showMobileMenu
  });
  return (
    <div className={mobileMenuClassnames}>
      <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <MobileMenuNavLink label="About" link="#">
          <MobileMenuSubNavLink label="Our Team" link={getUrl('our-team')} />
          <MobileMenuSubNavLink label="Our Story" link={getUrl('our-story')} />
        </MobileMenuNavLink>
        <MobileMenuNavLink label="Services" link={getUrl('services')} />
        <MobileMenuNavLink label="Events" link={getUrl('events')} />
        <MobileMenuNavLink label="Resources" link={getUrl('resources')} />

        <MobileMenuNavLink label="Contact" link={getUrl('contact')} />

        {/* <MobileMenuNavLink label="Search" link="/search" /> */}
        <MobileMenuNavLink
          label={props.authState ? 'Dashboard' : 'Login'}
          link={props.authState ? '/members/profile' : '/auth/login'}
        />
      </div>
    </div>
  );
};

interface BurgerProps {
  setShowMobileMenu: any;
  logo: any;
}
const Burger: React.FC<BurgerProps> = props => {
  return (
    <div className="flex p-4 lg:hidden">
      <button
        onClick={props.setShowMobileMenu}
        className="inline-flex items-center justify-center p-2 my-auto mr-4 rounded-md text-light_green bg-primary hover:text-blue-100 hover:bg-primary-dark"
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="block w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <svg
          className="hidden w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div>
        <Link to={getUrl('')}>
          <Img
            className="w-32 lg:w-48 my-2"
            fluid={props.logo.childImageSharp.fluid}
          />
        </Link>
      </div>
    </div>
  );
};

interface NavItemWrapperProps {
  children: React.ReactChild;
}
const NavItemWrapper = (props: NavItemWrapperProps) => (
  <li className="m-auto mr-6 whitespace-nowrap">{props.children}</li>
);

interface NavbarProps {
  siteTitle: string;
}
const Navbar: React.FC<NavbarProps> = props => {
  const auth = useContext(AuthContext);
  const cart = auth.authState?.cart?.payload;
  const couponUse = (auth?.authState?.cart as any)
    ?.couponUse as EventSectionsCartCouponUseModel;

  const [showSignInModal, setShowSignInModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState();
  const [dropdownOpen, setDropdownOpen] = useState<string>('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo_sprout.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const navigateToSignIn = () => {
    navigate(`/auth/login?intended=/members/event-cart/checkout`);
  };

  const goToCheckout = async () => {
    await updateCartAndReloadUser({ ...cart, display: false }, auth);
    if (!!auth.authState) {
      navigate(`/members/event-cart/checkout`);
    } else {
      setShowSignInModal(true);
    }
  };

  const isImpersonated = !!(auth.authState as any)?.impersonator;

  return (
    <nav
      role="navigation"
      className="z-10 bg-white border-b border-gray-300 shadow-lg"
    >
      <EventSectionsCart
        cart={cart}
        goToCheckout={goToCheckout}
        couponUse={couponUse}
      />

      <SignInModal
        isOpen={showSignInModal}
        onClose={() => setShowSignInModal(false)}
        navigateToSignin={navigateToSignIn}
      />
      <div className="h-1 bg-primary"></div>
      <div className="justify-between hidden px-8 py-4 lg:flex">
        <div className="flex">
          <div className="z-10 flex flex-col p-2 bg-white top-3 left-10">
            <Link to={getUrl('')}>
              <Img
                className="w-auto lg:w-64 m-2"
                fluid={data.logo.childImageSharp.fluid}
              />
            </Link>
          </div>
          <ul className="flex m-auto ml-12">
            <NavItemWrapper>
              <>
                <NavItem label="About" to="#" />
                <ul>
                  <li>
                    <SubNavItem label="Our Team" to={getUrl('our-team')} />
                  </li>
                  <li>
                    <SubNavItem label="Our Story" to={getUrl('our-story')} />
                  </li>
                </ul>
              </>
            </NavItemWrapper>
            <NavItemWrapper>
              <NavItem label="Services" to={getUrl('services')} />
            </NavItemWrapper>
            <NavItemWrapper>
              <NavItem
                label="Events"
                to={isImpersonated ? '/events' : getUrl('events')}
              />
            </NavItemWrapper>
            <NavItemWrapper>
              <NavItem label="Resources" to={getUrl('resources')} />
            </NavItemWrapper>
            <NavItemWrapper>
              <NavItem label="Contact" to={getUrl('contact')} />
            </NavItemWrapper>
          </ul>
        </div>
        <div className="flex">
          {/* <div className="hidden w-64 m-auto mr-4 xl:block">
            <SearchInput
              placeholder="Search"
              withButton={true}
              onChange={(e: any) => setSearchTerm(e.target.value)}
              onClick={() => {
                navigate(
                  searchTerm ? `/search?searchTerm=${searchTerm}` : `/search`
                );
              }}
            />
          </div> */}
          {/* <div className="m-auto xl:hidden">
            <NavItemWrapper>
              <NavItem label="Search" to="/search" />
            </NavItemWrapper>
          </div> */}
          <div className="m-auto whitespace-nowrap">
            <Link to={auth.authState ? '/members/profile' : '/auth/login'}>
              <Button text={auth.authState ? 'Dashboard' : 'Log In'} />
            </Link>
          </div>
          {!!cart?.items?.length && (
            <div className="m-auto ml-2 whitespace-nowrap">
              <ShoppingCartIcon
                className="cursor-pointer"
                color="#757263"
                width={30}
                onClick={() => {
                  if (cart?.items?.length) {
                    updateCartAndReloadUser(
                      {
                        ...cart,
                        display: true
                      },
                      auth
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Burger
        setShowMobileMenu={() => setShowMobileMenu(!showMobileMenu)}
        logo={data.logo}
      />
      <MobileMenu showMobileMenu={showMobileMenu} authState={auth.authState} />
    </nav>
  );
};

export default Navbar;
