import React from 'react';
import { useMemo } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accept, useDropzone } from 'react-dropzone';

export interface FileUploadProps {
  heading: string;
  subheading?: string;
  multiple?: boolean;
  onDropSuccess: any;
  onDropFailure: any;
  acceptedFileTypes?: Accept;
  renderAsButton?: boolean;
  buttonClasses?: string;
  buttonText?: string;
  buttonIcon?: JSX.Element;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const baseStyle = {
  display: 'block',
  width: '100%',
  borderWidth: '2px',
  borderColor: '#D1D5DB',
  borderStyle: 'dashed',
  borderRadius: '0.5em',
  padding: '3rem',
  textAlign: 'center',
  cursor: 'pointer'
};

const buttonStyle = {
  display: 'block',
  width: '80px'
};

const activeStyle = {
  backgroundColor: '#F3F4F6',
  borderColor: '#9CA3AF'
};

const FileUpload = ({
  heading = 'Drop a file here to upload',
  subheading = 'Acceptable file types include .pdf and .doc',
  onDropSuccess,
  onDropFailure,
  multiple,
  acceptedFileTypes = {
    'application/*': ['.doc', '.docx', '.pdf']
  },
  buttonClasses = 'bg-primary text-blue-100 px-3 py-2 rounded-lg hover:bg-primary_dark flex',
  buttonText = 'Upload',
  buttonIcon = (
    <FontAwesomeIcon
      icon={faCloudUploadAlt as IconProp}
      className="my-auto mr-2 w-4"
    />
  ),
  isLoading = false,
  renderAsButton = false,
  isDisabled = false
}: FileUploadProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    onDropAccepted: onDropSuccess,
    onDropRejected: onDropFailure,
    multiple,
    disabled: isDisabled || isLoading
  });

  let styles: any;

  if (renderAsButton) {
    styles = buttonStyle;
  } else {
    styles = baseStyle;
  }

  const style = useMemo(
    () => ({
      ...styles,
      ...(isDragActive ? activeStyle : {})
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive]
  );

  return (
    <div
      className={
        isDisabled || isLoading ? 'hover:cursor-default' : 'hover:bg-gray-100'
      }
      {...getRootProps({ style })}
    >
      <input {...getInputProps()} disabled={isDisabled || isLoading} />
      {renderAsButton ? (
        <button
          type="button"
          className={buttonClasses}
          disabled={isDisabled || isLoading}
        >
          {isLoading ? (
            <FontAwesomeIcon
              icon={faSpinner as IconProp}
              className="my-auto mr-2 w-4"
              spin
            />
          ) : (
            buttonIcon
          )}
          <span className="my-auto text-sm font-bold">{buttonText}</span>
        </button>
      ) : (
        <>
          {isLoading ? (
            <FontAwesomeIcon
              icon={faSpinner as IconProp}
              className="text-2xl text-gray-400"
              spin
            />
          ) : (
            <FontAwesomeIcon
              icon={faCloudUploadAlt as IconProp}
              className="text-2xl text-gray-400"
            />
          )}
          <span className="mt-2 block text-sm font-medium text-gray-900">
            {isLoading ? 'Loading...' : heading}
          </span>
          <span className="mt-2 block text-xs font-medium text-gray-900">
            {!isLoading ? subheading : <>&nbsp;</>}
          </span>
        </>
      )}
    </div>
  );
};

export default FileUpload;
