import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';

export const formatDateRange = (dates: string[], dateFormat = 'MMM d') => {
  if (!dates?.length) {
    return '';
  }
  if (dates.length === 1) {
    return formatInTimeZone(new Date(dates[0]), 'UTC', dateFormat);
  }
  const sortedDates = _.sortBy(dates, d => new Date(d));
  return `${formatInTimeZone(
    new Date(sortedDates[0]),
    'UTC',
    dateFormat
  )} - ${formatInTimeZone(
    new Date(sortedDates[sortedDates.length - 1]),
    'UTC',
    dateFormat
  )}`;
};

export const formatDates = (dates: string[], dateFormat?: string) => {
  try {
    return dates
      .map(d =>
        formatInTimeZone(new Date(d), 'UTC', dateFormat || 'MMM dd, yyyy')
      )
      .join(', ');
  } catch (e) {
    console.log('Invalid date: ', dates);
    return 'Invalid Date';
  }
};
