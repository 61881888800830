import React from 'react';

interface MembersPageTitleProps {
  title: string;
}
const MembersPageTitle = (props: MembersPageTitleProps) => {
  return (
    <h1 className="sans-serif text-primary text-3xl font-bold">
      {props.title}
    </h1>
  );
};

export default MembersPageTitle;
